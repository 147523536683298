"use client";

import { Navigation } from "swiper/modules";
import { useAppSelector } from "@/lib/store";
import { viewItemListAnalytics } from "@/lib/gtm";
import { sortProducts } from "@/lib/utils/helpers/home";
import React, { memo, useEffect, useMemo } from "react";
import CommonSwiper from "@/components/common/shared/Slider";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import { getContentfulImgData } from "@/lib/services/contentful";
import { formatProductGA4, mutateProductInfo } from "@/lib/utils";
import ProductsCard from "@/components/common/card/ProductsCard";
import ImageWithFallback from "@/components/common/ImageWithFallback";
import NextButton from "@/components/common/shared/Button/SwipperSliderBtns/next";
import PrevButton from "@/components/common/shared/Button/SwipperSliderBtns/prev";

const SeasonalProduct = ({
  data,
  seasonal,
  seasonalImageForMobile,
  headings = {},
}) => {
  const { storeConfig } = useAppSelector((state) => state.common);
  const seasonalImage = getContentfulImgData(seasonal?.fields?.image);
  const seasonalMobileImage = getContentfulImgData(
    seasonalImageForMobile?.fields?.image,
  );
  const { seasonal_products } = data || {};

  const seasonalProducts = useMemo(
    () => sortProducts(seasonal_products),
    [seasonal_products],
  );

  useEffect(() => {
    if (seasonal_products?.total_count) {
      // View cart GA4 for Seasonal viewed
      const formattedProducts = formatProductGA4(
        seasonalProducts
          .map((product) => mutateProductInfo(product))
          .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
      );

      viewItemListAnalytics(
        createItemListPayload(formattedProducts, "Seasonal Products", true),
      );
    }
  }, [seasonal_products]);

  if (!data || !seasonal_products?.total_count) return null;

  const sliderConfig = {
    speed: 300,
    breakpoints: {
      0: {
        spaceBetween: 8,
        slidesPerView: "auto",
        freeMode: true,
      },
      767: {
        spaceBetween: 16,
        slidesPerView: 3,
        freeMode: true,
      },
      1140: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: ".swiper-button-prev-season",
      nextEl: ".swiper-button-next-season",
    },
    modules: [Navigation],
  };

  const slides = seasonalProducts.map((product, index) => ({
    content: (
      <ProductsCard
        product={product}
        loop_index={index}
        item_list={"Seasonal Products"}
        imageLoader={{ loading: "lazy" }}
      />
    ),
  }));

  return (
    <section className="seasonSection">
      <div className="sm:hidden block absolute w-full h-full top-0 seasonSection-image">
        <ImageWithFallback
          width={320}
          height={122}
          alt={
            seasonalMobileImage.title ||
            seasonalMobileImage.description ||
            seasonalMobileImage.fileName
          }
          src={seasonalMobileImage.imageUrl}
          className="w-12/12 max-w-full h-full object-cover opacity-90"
        />
      </div>
      <div className="container pr-0 sm:pr-[0.75rem] lg:pr-5">
        <div className="row mr-0 sm:mr-[-0.75rem]">
          <div className="col sm:w-9/12 w-12/12 pr-0 xs:pr-[0.75rem]">
            <div className="flex justify-between xs:mb-24 mb-4 items-center xs:items-end">
              <div className="flex flex-col">
                <p className="home-heading sm:text-primary-black text-tertiary-white xs:mb-12 capitalize">
                  {storeConfig?.seasonal_product_title ||
                    headings?.seasonal_product_title}
                </p>
                <p className="sub-heading sm:text-primary-black text-tertiary-white">
                  {storeConfig?.seasonal_product_desc ||
                    headings?.seasonal_product_desc}
                </p>
              </div>
              <div className="items-center hidden sm:inline-flex">
                <PrevButton className="swiper-button-prev-season" />
                <NextButton className="swiper-button-next-season" />
              </div>
            </div>
            <CommonSwiper
              slides={slides}
              config={sliderConfig}
              className="customProductSlider"
            />
          </div>
          <div className="col sm:w-3/12 hidden sm:flex getReadyBox">
            <div className="relative w-12/12">
              <ImageWithFallback
                width={312}
                height={648}
                loading="lazy"
                alt={
                  seasonalImage.title ||
                  seasonalImage.description ||
                  seasonalImage.fileName
                }
                src={seasonalImage.imageUrl}
                className="w-12/12 max-w-full h-full rounded-[10px] object-fill xl:max-h-[590px]"
              />
              <span className="absolute text-tertiary-white px-5 bottom-[22px] xl:text-headline-3-b lg:text-subtitle-1 text-headline-4-sb max-w-full">{ seasonalImage?.title }</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(SeasonalProduct);
