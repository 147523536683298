"use client";

import "swiper/css/pagination";
import "swiper/css/navigation";
import Image from "next/image";
import { Navigation } from "swiper/modules";
import ReviewIcon from "@/assets/icon/reviewsio.svg?url";
import CommonSwiper from "@/components/common/shared/Slider";
import ReviewCard from "@/components/common/card/ReviewCard";
import ReviewPrevButton from "@/components/common/shared/Button/ReviewArrowBtns/prev";
import ReviewNextButton from "@/components/common/shared/Button/ReviewArrowBtns/next";

const ReviewSlider = ({ reviews }) => {
  const sliderConfig = {
    spaceBetween: 50,
    breakpoints: {
      0: {
        slidesPerView: "auto",
        spaceBetween: 8,
        freeMode: true,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 24,
        freeMode: true,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
    navigation: {
      prevEl: ".swiper-button-prev-review",
      nextEl: ".swiper-button-next-review",
    },
    modules: [Navigation],
  };

  const slides = reviews?.map((review) => {
    const { rating, comments, time_ago, author } = review || {};
    const { name, location } = author || {};

    return {
      content: (
        <ReviewCard
          userName={name}
          userComment={comments}
          rating={rating}
          address={location}
          time_ago={time_ago}
        />
      ),
    };
  });

  return (
    <div className="container pr-0">
      <div className="row mr-0">
        <div className="relative col w-12/12 xs:px-6 pr-0">
          <ReviewPrevButton className="swiper-button-prev-review" />
          <ReviewNextButton className="swiper-button-next-review" />
          <CommonSwiper
            slides={slides}
            config={sliderConfig}
            className="reviewSlider"
          />
        </div>
        <div className="flex justify-end w-12/12 pr-5">
          <Image width={100} height="auto" src={ReviewIcon} alt="review.io" />
        </div>
      </div>
    </div>
  );
};

export default ReviewSlider;
