import Stars from "../Stars";
import Image from "next/image";
import GreenTick from "@/assets/icon/green_tick.svg?url";

export default function ReviewCard(props) {
  return (
    <div className="review-card">
      <div className="mb-[46px]">
        <div className="flex flex-col xs:mb-4 mb-[12px]">
          <div className="flex mb-2 items-center">
            <h6 className="lg:text-body-text-1-xb xs:text-body-text-2-b text-body-text-3-b whitespace-pre-wrap  mr-2 text-left">
              {props.userName}
            </h6>
            <div className="flex">
              <Stars rating={props.rating} />
            </div>
          </div>
          <div className="flex items-center">
            <Image
              width={20}
              height={18}
              src={GreenTick}
              alt="Verified Customer"
              className="lg:w-5 xs:w-4 w-3 lg:h-5 xs:h-4 h-3"
            />
            <p className="lg:text-body-text-3-sb xs:text-minor-text-2 text-minor-text-mb-1 text-primary-black ml-1">
              Verified Customer
            </p>
          </div>
        </div>
        <div className="w-12/12">
          <p className="user-comment">{props.userComment}</p>
        </div>
      </div>
      <div className="flex xs:flex-row flex-col gap-1 text-left">
        <p className="text-body-text-3 text-primary-black-800 whitespace-nowrap">
          {props.address}
        </p>
        <p className="text-body-text-3 text-primary-black-800 whitespace-nowrap">
          {props.time_ago}
        </p>
      </div>
    </div>
  );
}
