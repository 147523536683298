export const isZendeskLoaded = () => {
  return typeof window.zE !== "undefined";
};

export const hideZendeskWidget = () => {
  setTimeout(() => {
    if (window.zE && window.zE.messenger) {
      try {
        window.zE.messenger.close();
      } catch (error) {
        console.error("Error closing Zendesk messenger:", error);
      }
    }
  }, 1000);
};

export const showZendeskWidget = () => {
  if (window?.zE && window.zE !== undefined && !window.zE?.messenger) {
    try {
      if (window.zE?.show) {
        window.zE.show();
      } else {
        window.zE("messenger", "show")?.catch((err) => {
          console.error("Failed to open messenger:", err);
          // Fallback to legacy widget if messenger fails
          try {
            window.zE("webWidget", "show");
          } catch (legacyError) {
            console.error("Error opening legacy widget:", legacyError);
          }
        });
      }
    } catch (error) {
      console.error("Error showZendeskWidget:", error);
    }
  }
};
