"use client";

import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { viewItemListAnalytics } from "@/lib/gtm";
import { Navigation, Scrollbar } from "swiper/modules";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import { getRecentlyViewedProducts } from "@/lib/services/home";
import {
  formatProductGA4,
  handleToastError,
  mutateProductInfo,
} from "@/lib/utils";
import {
  endCommonLoading,
  startCommonLoading,
} from "@/lib/store/reducers/common";
const CommonSwiper = dynamic(
  () => import("@/components/common/shared/Slider"),
  {
    ssr: false,
  },
);
const ProductsCard = dynamic(
  () => import("@/components/common/card/ProductsCard"),
  {
    ssr: false,
  },
);

const NextButton = dynamic(
  () => import("@/components/common/shared/Button/SwipperSliderBtns/next"),
  {
    ssr: false,
  },
);

const PrevButton = dynamic(
  () => import("@/components/common/shared/Button/SwipperSliderBtns/prev"),
  {
    ssr: false,
  },
);

export default function CartSection() {
  const dispatch = useAppDispatch();
  const { storeConfig } = useAppSelector((state) => state.common);

  const [recent, setRecent] = useState({});
  const specialCoupon = Cookies.get("specialCoupon") || "";

  const getProducts = useCallback(async () => {
    let recentProducts = localStorage.getItem("recently_viewed");

    try {
      recentProducts = recentProducts ? JSON.parse(recentProducts) : [];
    } catch (error) {
      recentProducts = [];
      console.error(error);
    }

    if (!recentProducts.length) {
      setRecent({});
      return;
    }
    if (recentProducts.length > 0) {
      dispatch(startCommonLoading());
      try {
        const { products, storeConfig } = await getRecentlyViewedProducts(
          recentProducts,
          specialCoupon,
          recentProducts,
        );

        // View cart GA4 for recently viewed
        const formattedProducts = formatProductGA4(
          products
            .map((product) => mutateProductInfo(product))
            .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
        );

        viewItemListAnalytics(
          createItemListPayload(formattedProducts, "Recently Viewed", true),
        );

        setRecent({ products, storeConfig });
      } catch (error) {
        handleToastError(error.message);
      } finally {
        dispatch(endCommonLoading());
      }
    }
  }, [dispatch]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  if (recent && !recent?.products?.length) return null;

  const sliderConfig = {
    spaceBetween: 24,
    scrollbar: { draggable: true },
    modules: [Scrollbar, Navigation],
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    breakpoints: {
      0: {
        slidesPerView: "auto",
        spaceBetween: 8,
        freeMode: true,
      },
      767: {
        slidesPerView: "auto",
        spaceBetween: 16,
        freeMode: true,
      },
      1140: {
        slidesPerView: "auto",
        spaceBetween: 24,
      },
      1441: {
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
  };

  const slides = recent?.products.map((product, index) => ({
    content: (
      <ProductsCard
        product={product}
        loop_index={index}
        item_list={"Recently Viewed Products"}
      />
    ),
  }));

  return (
    <section className="cartSection">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="flex justify-between xs:mb-8 mb-4 items-baseline xs:items-end">
              <div className="flex flex-col">
                <p className="home-heading xs:mb-12">
                  {storeConfig?.recentlyview_product_title}
                </p>
                <p className="sub-heading">
                  {storeConfig?.recentlyview_product_desc}
                </p>
              </div>
              <div className="items-center hidden xs:inline-flex">
                <PrevButton className="swiper-button-prev" />
                <NextButton className="swiper-button-next" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container custom">
        <div className="row mr-0">
          <div className="col w-12/12 pr-0">
            <CommonSwiper
              slides={slides}
              config={sliderConfig}
              className="productSlider"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
