import { keys } from "../keys";
import { constants } from "../constants";
import { resolveURL } from "@/lib/services/product";

export const sortProducts = (products = []) => {
  if (!products || !Array.isArray(products?.items)) {
    return [];
  }

  // Create a copy of the array and sort it
  const sortedProducts = [...products.items].sort((a, b) => {
    if (a.list_sort === null && b.list_sort !== null) {
      return 1;
    }
    if (a.list_sort !== null && b.list_sort === null) {
      return -1;
    }
    return 0;
  });

  return sortedProducts;
};

export const checkCurrentURL = async ({ url, slug = "" }) => {
  const lastSegment = url[url.length - 1];
  let urlPath = url.join("/");
  if (keys.general.addExtension) {
    urlPath = urlPath.concat(".html");
  }

  const path = `/${decodeURIComponent(urlPath)}`;
  const { data } = await resolveURL({ path });
  if (!data?.urlResolver?.id) {
    console.warn("Slug: " + slug + ", URL cannot be resolved: " + path);
  }

  const { type, breadcrumbs, ...rest } = data?.urlResolver || {};
  const isCarParts = rest?.is_type_service_part === 1;
  const hasChildren = rest?.has_children && parseInt(rest?.has_children) > 0;
  return {
    type,
    rest,
    is3BO: rest?.is_threebo_view === 1,
    isCarParts,
    lastSegment,
    hasChildren,
    breadcrumbs,
  };
};

export const isInvalidRouteForResolve = (pathname) => {
  return [
    ...constants.image_extensions,
    ...constants.file_extensions,
    ...constants.next_routes,
  ].some((ext) => pathname.endsWith(ext));
};
