"use client";

import Cookies from "js-cookie";
import CarModelBox from "./carModel";
import {
  constants,
  MVLKeyToQueryString,
  MVLPayloadToKey,
  handleToastError,
  schemas,
} from "@/lib/utils";
import { useForm } from "react-hook-form";
import FindCarParts from "./findCarParts";
import { useTranslation } from "@/i18n/client";
import ExportVehicleBox from "./exportVehicle";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDetailFromRedis } from "@/lib/services/api";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import Button from "@/components/common/shared/Button/button";
import {
  endCommonLoading,
  startCommonLoading,
  updateVehicle,
} from "@/lib/store/reducers/common";
import { registration_lookup } from "@/lib/gtm";

export default function CarRegistrationBox() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["garage", "common"]);
  const { vehicle } = useAppSelector((state) => state.common);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      reg: "",
      make: "",
      model: "",
      type: "",
      year: "",
      engine: "",
      body: "",
      kw_hp: "",
    },
    resolver: yupResolver(schemas.checkVehicleSchema(() => {})),
  });

  const [isExportBox, setIsExportBox] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // Function to toggle the class
  const toggleClass = () => {
    setIsExportBox(!isExportBox); // Toggle the state
  };

  const findVehicle = async (data) => {
    let { make, model, type, year, engine, body, kw_hp, reg } = data;
    reg = reg.replace(/\s/g, "");
    let payload = {
      make,
      model,
      type,
      year,
      engine,
      body,
      kw_hp,
    };
    if (!isActive && !reg) {
      handleToastError(t("messages.error.required_vehicle_registration"));
      return;
    }
    try {
      dispatch(startCommonLoading());
      if (reg) {
        const res = await getDetailFromRedis(`vrm=${reg}`, true);
        if (!res) {
          handleToastError(t("messages.no_results.vehicle"));
          return;
        }

        registration_lookup({
          manufacturer: res?.vehicle?.make,
          model: res?.vehicle?.model,
          years: res?.vehicle?.dateFirstRegistered?.match(/^(\d{4})/)[0],
          engine: res?.vehicle?.engineSize,
        });
        dispatch(updateVehicle(res));
        toggleClass();
        reset();
      } else {
        const queryString = MVLKeyToQueryString(MVLPayloadToKey(payload));
        const res = await getDetailFromRedis(queryString, true);
        if (!res) {
          handleToastError(t("messages.no_results.vehicle"));
          return;
        }
        dispatch(updateVehicle(res));
        toggleClass();
        reset();
        registration_lookup({
          manufacturer: res?.vehicle?.make,
          model: res?.vehicle?.model,
          years:res?.vehicle?.modelVersion?.match(/\d{4}/)[0],
          ...(res?.vehicle?.engineSize && { engine: res?.vehicle?.engineSize }),
        });
      }
    } catch (error) {
      handleToastError(error.message);
    } finally {
      dispatch(endCommonLoading());
    }
  };

  const handleChangeVehicle = () => {
    reset();
    dispatch(updateVehicle(null));
    Cookies.remove(constants.cookies.selectedVehicle);
    Cookies.remove(constants.cookies.new_vehicle);
  };

  useEffect(() => {
    setIsExportBox(vehicle !== null);
  }, [vehicle]);

  return (
    <div
      className={`carRegistrationBox ${
        isExportBox ? "slide-in-top" : "slide-in-bottom"
      } `}
    >
      {isExportBox ? (
        <div className="btnBox details">
          <Button
            theme="white-secondary-btn"
            icon="carBlue"
            iconPosition="left"
          >
            {t("vehicle.yourVehicle")}
          </Button>
        </div>
      ) : (
        <div className="btnBox">
          <Button
            theme={`${isActive ? "grey-secondary-btn" : "white-secondary-btn"}`}
            onClick={() => setIsActive(false)}
            icon="edit"
            iconPosition="left"
            className={isActive ? "" : "active"}
          >
            {t("vehicle.byRegistration")}
          </Button>
          <Button
            theme={`${
              !isActive ? "grey-secondary-btn" : "white-secondary-btn"
            }`}
            onClick={() => setIsActive(true)}
            icon="car"
            iconPosition="left"
            className={isActive ? "active" : ""}
          >
            {t("vehicle.byModel")}
          </Button>
        </div>
      )}
      {isActive ? (
        !isExportBox && <CarModelBox findVehicles={findVehicle} t={t} />
      ) : (
        <FindCarParts
          value={isExportBox}
          register={register}
          handleSubmit={handleSubmit}
          
          findVehicles={findVehicle}
          reset={reset}
          t={t}
        />
      )}

      <ExportVehicleBox
        value={isExportBox}
        toggleValue={toggleClass}
        handleChangeVehicle={handleChangeVehicle}
        t={t}
      />
    </div>
  );
}
