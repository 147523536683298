"use client";

import { Controller } from "react-hook-form";
import CustomSelect from "@/components/common/shared/Select";
import { useCallback } from "react";

const ManualVehicleSelection = ({
  name,
  label,
  options,
  control,
  isDisable,
  placeholder,
  handleChange = () => {},
}) => {
  const handleSelection = useCallback(
    (value, callback) => {
      callback(value);
      handleChange(name);
    },
    [name, handleChange],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: false }}
      render={({ field: { onChange, value: fVal, ref } }) => {
        const value = options?.find(
          (c) => c.value === fVal?.value || c.value === fVal,
        );
        return (
          <CustomSelect
            id={name}
            ref={ref}
            title={label}
            options={options}
            value={value ?? ""}
            isSearchAllow={true}
            isDisable={isDisable}
            labelClasses="capitalize"
            placeholder={placeholder}
            onChange={(val) => handleSelection(val?.value ?? "", onChange)}
          />
        );
      }}
    />
  );
};

export default ManualVehicleSelection;
