"use client";

import Button from "@/components/common/shared/Button/button";
import ManualVehicleSelection from "../CurrentVehicle/ManualVehicleSelection";
import { Fragment } from "react";
import { useVehicleSelectionController } from "@/lib/hooks";
import Image from "next/image";
import Info from "@/assets/icon/info.svg?url";
const CarModelBox = ({ findVehicles, t }) => {
  const {
    data: { control, optionsList, enableSearchBtn },
    methods: {
      handleSubmit,
      searchVehicleFromMVL,
      handleDropdownSelection,
      getValues,
    },
  } = useVehicleSelectionController({ manualSearch: true });
  const yearValue = getValues("year");

  return (
    <form noValidate onSubmit={handleSubmit(findVehicles)}>
      <div className="selectValueBox">
        <div
          className={`findByRegistrationBox carModel ${
            !yearValue || (yearValue && optionsList.engine.length > 0)
              ? ""
              : "three-value"
          }`}
        >
          <div className="vrm-container">
            <div className="selectBox">
              <ManualVehicleSelection
                name="make"
                label={t("current_vehicle.make.title")}
                control={control}
                placeholder={t("current_vehicle.make.placeholder")}
                options={optionsList.make}
                isDisable={!optionsList.make.length}
                handleChange={handleDropdownSelection}
              />
              <ManualVehicleSelection
                name="model"
                label={t("current_vehicle.model.title")}
                control={control}
                placeholder={t("current_vehicle.model.placeholder")}
                options={optionsList.model}
                isDisable={!optionsList.model.length}
                handleChange={handleDropdownSelection}
              />
              <ManualVehicleSelection
                name="year"
                label={t("current_vehicle.year.title")}
                control={control}
                options={optionsList.year}
                placeholder={t("current_vehicle.year.placeholder")}
                isDisable={!optionsList.year.length}
                handleChange={handleDropdownSelection}
              />
              {(!yearValue || (yearValue && !!optionsList.engine.length)) && (
                <ManualVehicleSelection
                  name="engine"
                  label={t("current_vehicle.engine.title")}
                  control={control}
                  placeholder={t("current_vehicle.engine.placeholder")}
                  options={optionsList.engine}
                  isDisable={!optionsList.engine.length}
                  handleChange={handleDropdownSelection}
                />
              )}
              {/* {optionsList.engine.length > 0 && (
                <Fragment>
                  */}
              {optionsList.body?.length > 0 && (
                <Fragment>
                  <ManualVehicleSelection
                    name="body"
                    label={t("current_vehicle.body.title")}
                    control={control}
                    placeholder={t("current_vehicle.body.placeholder")}
                    options={optionsList.body}
                    handleChange={handleDropdownSelection}
                  />
                  {optionsList.kw_hp.length > 0 && (
                    <ManualVehicleSelection
                      name="kw_hp"
                      label={t("current_vehicle.kw_hp.title")}
                      control={control}
                      placeholder={t("current_vehicle.kw_hp.placeholder")}
                      options={optionsList.kw_hp}
                      handleChange={searchVehicleFromMVL}
                    />
                  )}
                </Fragment>
              )}
              {/* </Fragment>
              )} */}
            </div>
            <Button
              theme="green-btn"
              disabled={enableSearchBtn}
              className="xl:ml-3 ml-0"
            >
              {t("button.find_parts")}
            </Button>
          </div>
          <div className="flex-center mt-2 xs:mt-[14px]">
            <Image
              width={24}
              height={24}
              src={Info}
              alt="info"
              className="me-1 xs:max-w-[24px] max-w-[15px] xs:top-0 -top-[1px] relative"
            />
            <p className="text-minor-text-1-mb xs:text-body-text-3-m lg:text-body-text-2-m text-primary-black">
              {t("button.select_vehicle")}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};
export default CarModelBox;
