import axios from "axios";
import { productFragments } from "./fragments";
import { handleGraphqlResponse, isSSR, keys } from "../utils";
import { getYouMayLikeProducts } from "./cart";

const baseRequest = async (url, query) => {
  try {
    const { data } = await axios.post(url, {
      query,
    });
    return [handleGraphqlResponse(data), null];
  } catch (err) {
    return [
      null,
      {
        message: err.message,
      },
    ];
  }
};

// get seasonal products for homepage
export const getSeasonalProducts = ({ specialCoupon }) => {
  return baseRequest(
    keys.general.graphqlUrl,
    `{
      seasonal_products:products (
        filter: {seasonal_product: { eq: 1 }, price:{from:0.01} }
        sort: {seasonal_product_sort_order: ASC }
        pageSize: 6
        specialCoupon: "${specialCoupon || ""}"
      ) {
        total_count
        items {
            ...Products
            list_sort : seasonal_product_sort_order
        }
      }
    }
    ${productFragments.listing}`,
  );
};

// get seasonal products for homepage
export const getFeaturedProducts = ({ specialCoupon }) => {
  const resolvedUrl = isSSR()
    ? keys.general.graphqlUrl
    : keys.general.backendGraphqlUrl;
  return baseRequest(
    resolvedUrl,
    `{
      featured_products:products(
        filter: {featured_product: { eq: 1 }, price:{from:0.01} }
        sort: {featured_product_sort_order: ASC }
        pageSize: 8
        specialCoupon: "${specialCoupon || ""}"
      ) {
        total_count
        items {
            ...Products
            list_sort : featured_product_sort_order
        }
      }
    }
    ${productFragments.listing}`,
  );
};

// get popular categories for homepage
export const getPopularCategories = (withoutStoreConfig = false) => {
  const categoriesQuery = `getPopularCategories (currentStoreId:1${
    withoutStoreConfig ? "" : ", pageSize:6"
  }) {
    pageSize
    category {
      id
      name
      url_path
      image
      description
      meta_title
      meta_keyword
      meta_description
    }
  }`;

  return baseRequest(keys.general.graphqlUrl, `{ ${categoriesQuery} }`);
};

// get recently viewed products for homepage
export const getRecentlyViewedProducts = async (
  payload,
  specialCoupon,
  recentProducts = [],
) => {
  const queryParam = payload.map((val) => `"${val}"`);
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `{
      products(filter: { sku: {  in: [${queryParam.join(
        ", ",
      )}] }, price:{from:0.01} },specialCoupon: "${specialCoupon || ""}") {
        total_count
        items {
          ...Products
        }
      }
    }
    ${productFragments.recently_viewed}`,
  });
  const data = handleGraphqlResponse(axiosRes.data);
  let products = recentProducts
    ?.map((id) => data?.products?.items?.find((p) => p.sku == id))
    ?.filter?.(Boolean);

  if (products?.length < 4) {
    const pageSize = 4 - products.length; // Dynamically calculate pageSize
    const cartproductsRes = await getYouMayLikeProducts({
      pageSize, // Adjust the pageSize based on remaining products needed
      order: "ASC",
      specialCoupon,
    });
    const cartproductsData = handleGraphqlResponse(cartproductsRes);
    const remainigProducts = cartproductsData?.products?.items;
    products = [...products, ...remainigProducts];
  }
  return { products, storeConfig: data?.storeConfig };
};
