"use client";

import { useEffect, useCallback } from "react";
import { handleToastError } from "@/lib/utils";

const ErrorHandler = ({ err = [] }) => {
  const checkErrorExist = useCallback(() => {
    let errorMessage = "";
    const filteredErrors = err.filter((el) => el !== null);
    if (filteredErrors.length > 0) {
      errorMessage = filteredErrors[0]?.message || filteredErrors[0];
      if (typeof errorMessage !== "string") {
        errorMessage = "Something went wrong!";
      }
    }
    return errorMessage;
  }, [err]);

  useEffect(() => {
    const message = checkErrorExist();
    if (message) handleToastError(message);
  }, [err, checkErrorExist]);
  return null;
};

export default ErrorHandler;
