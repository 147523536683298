"use client";

import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Link from "next/link";
import { Autoplay, Pagination } from "swiper/modules";
import CommonSwiper from "@/components/common/shared/Slider";
import { getContentfulImgData } from "@/lib/services/contentful";
import ImageWithFallback from "@/components/common/ImageWithFallback";

export default function ShopNow({
  sliderTime,
  promotionalBanner,
  promotionalBannerForMobile,
}) {
  const sliderConfig = {
    slidesPerView: 1,
    pagination: { clickable: true },
    modules: [Pagination, Autoplay],
    className: "megaSaleSwiper",
    speed: 300,
    autoplay: {
      delay: sliderTime || "7000",
      disableOnInteraction: false,
    },
    loop: true,
  };

  const slides = promotionalBanner?.map?.((banner, index) => {
    const bannerImageDesktop = getContentfulImgData(banner?.fields?.image);
    const bannerImageMobile = getContentfulImgData(
      promotionalBannerForMobile?.[index]?.fields?.image,
    );
    const bannerLink = banner?.fields?.link;

    const BannerImage = (
      <picture>
        {/* Source for mobile devices */}
        <source
          media="(max-width: 450px)"
          srcSet={bannerImageMobile?.imageUrl}
        />
        {/* Source for desktop devices */}
        <source
          media="(min-width: 451px)"
          srcSet={bannerImageDesktop?.imageUrl}
        />

        {/* Fallback image */}
        <ImageWithFallback
          loading="lazy"
          src={bannerImageDesktop?.imageUrl}
          width={bannerImageDesktop?.width || 1440}
          height={bannerImageDesktop?.height || 440}
          className="w-12/12 object-cover xs:rounded-lg rounded-[0px] h-auto"
          alt={
            bannerImageDesktop?.title ||
            bannerImageDesktop?.description ||
            bannerImageDesktop?.fileName ||
            "Banner Image"
          }
        />
      </picture>
    );

    return {
      content: (
        <div className="relative">
          {bannerLink ? (
            <Link prefetch={false} href={bannerLink}>
              {BannerImage}
            </Link>
          ) : (
            BannerImage
          )}
        </div>
      ),
    };
  });

  return (
    <section className="megaSaleSection bg-tertiary-white xs:pb-80 pb-24">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="relative w-12/12 greyBanner">
              <CommonSwiper slides={slides} config={sliderConfig} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
