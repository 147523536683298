"use client";

import { useEffect } from "react";
import {
  isZendeskLoaded,
  hideZendeskWidget,
  showZendeskWidget,
} from "@/lib/utils/helpers/zendesk";

export default function ZendeskWidgetManger({ show = true }) {
  useEffect(() => {
    if (isZendeskLoaded()) {
      if (show) {
        showZendeskWidget();
      } else {
        hideZendeskWidget();
      }
    }
  }, [show]);

  return null;
}
