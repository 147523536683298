import Link from "next/link";
import { constants } from "@/lib/utils";
import ImageWithFallback from "../ImageWithFallback";
import ArrowRight from "@/assets/icon/arrow_right_alt.svg?url";

export default function CategoryCard({ heading, description, image, href }) {
  return (
    <Link prefetch={false} href={href} className="category-card-section">
      <div className="xs:p-5 xs:pt-[28px] xs:pb-12 p-3 text-left">
        <div className="flex justify-between items-center relative">
          <h3 className="heading-20 xs:text-primary-black text-primary-black-700 mb-1 z-10 relative">
            {heading}
          </h3>
          <ImageWithFallback
            width={24}
            height={24}
            src={ArrowRight}
            alt="arrow-right"
            className="icon"
          />
        </div>
        <p className="category-card-content max-w-[95%] z-10 relative">
          {description}
        </p>
      </div>
      <div className="flex justify-start items-end">
        <picture className="flex h-full xs:max-h-[149px] w-12/12">
          <ImageWithFallback
            src={image} // Default image
            width={117}
            height={92}
            alt={heading}
            type={constants.image_types.category}
            className="w-[117px] xs:w-12/12 xs:max-w-[152px] h-auto z-10 relative"
          />
        </picture>
      </div>

      {/* <div className="absolute xs:bottom-5 bottom-3 left-1/2 transform -translate-x-1/2 xs:flex hidden z-10">
        <Button type="button" icon="arrow" theme="white-mini-btn">
          View More
        </Button>
      </div> */}
    </Link>
  );
}
