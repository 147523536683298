const ReviewPrevButton = ({ className }) => {
  return (
    <button className={`${className} arrow-btn`}>
      <svg
        width="11"
        height="19"
        viewBox="0 0 11 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.42263 9.38379L10.2111 16.1723C10.4419 16.403 10.5599 16.6931 10.5653 17.0425C10.5706 17.3918 10.4526 17.6872 10.2111 17.9286C9.96964 18.1701 9.67692 18.2908 9.33292 18.2908C8.98892 18.2908 8.6962 18.1701 8.45475 17.9286L0.964377 10.4383C0.808377 10.2823 0.698335 10.1178 0.634252 9.94467C0.570141 9.77161 0.538086 9.58466 0.538086 9.38379C0.538086 9.18293 0.570141 8.99598 0.634252 8.82292C0.698335 8.64984 0.808377 8.48531 0.964377 8.32934L8.45475 0.838961C8.6855 0.608183 8.97556 0.490127 9.32492 0.484794C9.67425 0.479433 9.96964 0.597488 10.2111 0.838961C10.4526 1.0804 10.5733 1.37313 10.5733 1.71713C10.5733 2.06113 10.4526 2.35385 10.2111 2.59529L3.42263 9.38379Z"
          fill="#1D242B"
        />
      </svg>
    </button>
  );
};
export default ReviewPrevButton;
