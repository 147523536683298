/**
 * @param {{rating: number}} param0
 * @returns {React.ReactNode}
 */

import { useMemo } from "react";

const Stars = ({ rating }) => {
  const fullStars = useMemo(() => Math.floor(rating), [rating]);
  const showHalfStar = rating - fullStars > 0;

  return (
    <div className="flex items-center star-rating gap-1">
      {Array.from(Array(fullStars)).map((_, idx) => (
        <svg
          key={`review-stars-${idx}`}
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9425_125259)">
            <path
              d="M9.94071 14.5436C9.66931 14.3792 9.329 14.3792 9.05761 14.5437L4.89532 17.0665C4.25017 17.4575 3.45263 16.8789 3.62406 16.1443L4.72755 11.4153C4.79924 11.1081 4.69529 10.7864 4.45739 10.5792L0.793637 7.38862C0.226126 6.8944 0.531211 5.96092 1.28105 5.89724L6.10934 5.48723C6.42375 5.46053 6.69766 5.26223 6.8212 4.97188L8.71605 0.518403C9.01015 -0.172801 9.98985 -0.172801 10.2839 0.518404L12.1788 4.97188C12.3023 5.26224 12.5762 5.46053 12.8907 5.48723L17.7189 5.89724C18.4688 5.96092 18.7739 6.8944 18.2064 7.38862L14.5426 10.5792C14.3047 10.7864 14.2008 11.1081 14.2725 11.4153L15.376 16.1446C15.5474 16.8792 14.75 17.4578 14.1048 17.0668L9.94071 14.5436Z"
              fill="#FFD451"
            />
          </g>
          <defs>
            <clipPath id="clip0_9425_125259">
              <rect
                width="18"
                height="17.25"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ))}

      {showHalfStar && (
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9425_125265)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.09858 15.4489L8.39522 13.4508C9.07368 13.0396 9.92446 13.0395 10.603 13.4507L13.9015 15.4494L13.028 11.7057C12.8487 10.9376 13.1086 10.1334 13.7034 9.61547L16.6086 7.08548L12.7825 6.76058C11.9965 6.69383 11.3117 6.1981 11.0029 5.47221L9.5 1.93998L7.99712 5.47221C7.68827 6.19809 7.0035 6.69383 6.21747 6.76058L2.39144 7.08548L5.29664 9.61547C5.8914 10.1334 6.15127 10.9376 5.97205 11.7057L5.09858 15.4489ZM9.05761 14.5437C9.329 14.3792 9.66931 14.3792 9.94071 14.5436L14.1048 17.0668C14.75 17.4578 15.5474 16.8792 15.376 16.1446L14.2725 11.4153C14.2008 11.1081 14.3047 10.7864 14.5426 10.5792L18.2064 7.38862C18.7739 6.8944 18.4688 5.96091 17.7189 5.89724L12.8907 5.48723C12.5762 5.46053 12.3023 5.26224 12.1788 4.97188L10.2839 0.518404C9.98985 -0.1728 9.01015 -0.172801 8.71605 0.518403L6.8212 4.97188C6.69766 5.26223 6.42375 5.46053 6.10934 5.48723L1.28105 5.89724C0.531211 5.96091 0.226126 6.8944 0.793637 7.38862L4.45739 10.5792C4.69529 10.7864 4.79924 11.1081 4.72755 11.4153L3.62406 16.1443C3.45263 16.8789 4.25017 17.4575 4.89532 17.0665L9.05761 14.5437Z"
              fill="#FFD451"
            />
            <mask
              id="mask0_9425_125265"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="19"
              height="18"
            >
              <path
                d="M9.94071 14.5436C9.66931 14.3792 9.329 14.3792 9.05761 14.5437L4.89532 17.0665C4.25017 17.4575 3.45263 16.8789 3.62406 16.1443L4.72755 11.4153C4.79924 11.1081 4.69529 10.7864 4.45739 10.5792L0.793637 7.38862C0.226126 6.8944 0.531211 5.96092 1.28105 5.89724L6.10934 5.48723C6.42375 5.46053 6.69766 5.26223 6.8212 4.97188L8.71605 0.518403C9.01015 -0.172801 9.98985 -0.172801 10.2839 0.518404L12.1788 4.97188C12.3023 5.26224 12.5762 5.46053 12.8907 5.48723L17.7189 5.89724C18.4688 5.96092 18.7739 6.8944 18.2064 7.38862L14.5426 10.5792C14.3047 10.7864 14.2008 11.1081 14.2725 11.4153L15.376 16.1446C15.5474 16.8792 14.75 17.4578 14.1048 17.0668L9.94071 14.5436Z"
                fill="#FFC500"
              />
            </mask>
            <g mask="url(#mask0_9425_125265)">
              <rect x="0.5" width="9" height="17.1907" fill="#FFD451" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_9425_125265">
              <rect
                width="18"
                height="17.25"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default Stars;
