import Input from "@/components/common/shared/Input/input";
import UkFlag from "@/assets/static/ukFlag.svg?url";
import Image from "next/image";
import Button from "@/components/common/shared/Button/button";
import { useEffect } from "react";
import Info from "@/assets/icon/info.svg?url";

const FindCarParts = ({
  value,
  register,
  handleSubmit,
  findVehicles,
  t,

  reset,
}) => {
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(findVehicles)}>
      <div className={`selectValueBox ${value ? "hide" : "flex"}`}>
        <div className="findByRegistrationBox">
          <div className="flex justify-between xs:flex-row flex-col">
            <div className="inputBox">
              <span>
                <Image
                  width={54}
                  height={54}
                  alt="UK"
                  src={UkFlag}
                  sizes="(max-width: 768px) 100vw, 
        (max-width: 1200px) 50vw, 
        33vw" // How the image scales at different breakpoints
                />
              </span>
              <Input
                name="reg"
                type="text"
                {...register("reg")}
                id="registration-input"
                className="find-car-input"
                placeholder={t("enterReg")}
              />
            </div>
            <Button theme="green-btn" type="submit">
              {t("button.find_parts")}
            </Button>
          </div>
          <div className="flex-center mt-2 xs:mt-[14px]">
            <Image
              width={24}
              height={24}
              src={Info}
              alt="info"
              className="me-1 xs:max-w-[24px] max-w-[15px] xs:top-0 -top-[1px] relative"
            />
            <p className="text-minor-text-1-mb xs:text-body-text-3-m lg:text-body-text-2-m text-primary-black">
              {t("current_vehicle.enterNumberPlate")}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};
export default FindCarParts;
