import { constants } from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
import OptimizedImage from "../../OptimizedImage";
import { getGlobalArgs } from "@/lib/store/reducers/common";

export default function Badge({ recommended = true, bestSeller = true }) {
  const { bestseller: bestSellerBadge, recommended: recommendedBadge } =
    useAppSelector(getGlobalArgs) || {};

  return (
    <div className="relative xs:gap-2 xs:absolute flex flex-col gap-1 xs:top-[10px]">
      {bestSeller && (
        <div className="w-auto h-[22px] max-h-[22px] relative mb-2 xs:mb-0">
          <OptimizedImage
            alt="Best seller"
            key={bestSellerBadge}
            src={bestSellerBadge}
            type={constants.image_types.product_best_seller}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            fill
            className="object-contain object-left"
          />
          <span className="invisible">Best seller product</span>
        </div>
      )}
      {recommended && (
        <div className="w-auto h-[22px] max-h-[22px] relative">
          <OptimizedImage
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            fill
            alt="Recommended"
            key={recommendedBadge}
            src={recommendedBadge}
            type={constants.image_types.product_recommended}
            className="w-auto h-[22px] max-h-[22px] object-contain object-left"
          />
          <span className="invisible">Recommended product</span>
        </div>
      )}
    </div>
  );
}
