"use client";
// CommonSwiper.js
import React from "react";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const CommonSwiper = ({ slides, config, className }) => {
  return (
    <Swiper {...config} className={className}>
      {slides.map((slide, index) => (
        <SwiperSlide key={slide?.key ? slide?.key : index}>
          {slide.content}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CommonSwiper;
