"use client";

import { Grid } from "swiper/modules";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import { viewItemListAnalytics } from "@/lib/gtm";
import { sortProducts } from "@/lib/utils/helpers/home";
import CommonSwiper from "@/components/common/shared/Slider";
import Button from "@/components/common/shared/Button/button";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import ProductsCard from "@/components/common/card/ProductsCard";
import { formatProductGA4, mutateProductInfo, routes } from "@/lib/utils";

export default function Deals({ featuredData = [], headings = {} }) {
  const { storeConfig } = useAppSelector((state) => state.common);
  const { t } = useTranslation("home");

  const products = useMemo(() => {
    if (featuredData?.length) {
      const [data] = featuredData;
      if (data) {
        return {
          items: sortProducts(data?.featured_products),
          count: data?.featured_products?.total_count,
        };
      }
    }
    return null;
  }, [featuredData]);

  useEffect(() => {
    if (products?.count) {
      // View cart GA4 for Seasonal viewed
      const formattedProducts = formatProductGA4(
        products?.items
          .map((product) => mutateProductInfo(product))
          .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
      );

      viewItemListAnalytics(
        createItemListPayload(formattedProducts, "Featured Products", true),
      );
    }
  }, [products]);

  if (!products?.count) return null;

  const sliderConfig = {
    breakpoints: {
      0: {
        spaceBetween: 8,
        slidesPerView: "auto",
        grid: { rows: 1, fill: "row" },
        freeMode: true,
      },
      767: {
        spaceBetween: 16,
        slidesPerView: 3,
        grid: { rows: 1, fill: "row" },
        freeMode: true,
      },
      1140: {
        spaceBetween: 24,
        slidesPerView: 4,
        grid: { rows: 2, fill: "row" },
      },
    },
    modules: [Grid],
  };

  const slides = products.items.map((product, index) => ({
    content: (
      <ProductsCard
        product={product}
        loop_index={index}
        item_list={"Featured Products"}
        imageLoader={{ loading: "lazy" }}
      />
    ),
  }));

  return (
    <section className="w-12/12 pb-6 sm:pb-80 bg-tertiary-white category-section">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="flex justify-between items-baseline xs:items-end">
              <div className="flex flex-col">
                <p className="home-heading xs:mb-12">
                  {storeConfig.featured_product_title ||
                    headings?.featured_product_title}
                </p>
                <p className="sub-heading">
                  {storeConfig.featured_product_desc ||
                    headings?.featured_product_desc}
                </p>
              </div>
              <div className="flex items-center">
                <Button
                  type="link"
                  href={routes.deals}
                  theme="blue-bordered-btn-arrow"
                  icon="arrow"
                  className="font-semibold"
                >
                  {t("btns.explore_deals_btn")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pr-0 xs:pr-[0.75rem] lg:pr-5">
        <div className="row mr-0 xs:mr-[-0.75rem]">
          <div className="col w-12/12 pr-0 xs:pr-[0.75rem]">
            <CommonSwiper
              slides={slides}
              config={sliderConfig}
              className="swiper swiper-grid swiper-initialized swiper-horizontal customProductSlider deals-product-slider swiper-backface-hidden"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
