import "@/assets/css/pages/deals.css"
const SpecialPriceBadge = ({ showBadge = false, price = "0.00" }) => {
  if (!showBadge) return null;
  return (
    <div className="blue-sale-box">
      <p className="xs:text-minor-text-2 text-minor-text-mb-1 break-words">
        Save{" "}
      </p>
      <span className="xs:text-minor-text-mb-700 text-minor-text-sm-9p xs:font-bold font-[900]">{price}</span>
    </div>
  );
};

export default SpecialPriceBadge;
