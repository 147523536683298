const ReviewNextButton = ({ className }) => {
  return (
    <button className={`${className} arrow-btn`}>
      <svg
        width="11"
        height="19"
        viewBox="0 0 11 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.57742 9.38409L0.788967 2.59559C0.558189 2.36484 0.440134 2.07479 0.4348 1.72543C0.429439 1.37609 0.547495 1.0807 0.788967 0.839259C1.03041 0.597787 1.32313 0.477051 1.66713 0.477051C2.01113 0.477051 2.30386 0.597787 2.5453 0.839259L10.0357 8.32963C10.1917 8.4856 10.3017 8.65013 10.3658 8.82322C10.4299 8.99627 10.462 9.18323 10.462 9.38409C10.462 9.58495 10.4299 9.77191 10.3658 9.94497C10.3017 10.118 10.1917 10.2826 10.0357 10.4385L2.5453 17.9289C2.31455 18.1597 2.02449 18.2778 1.67513 18.2831C1.3258 18.2885 1.03041 18.1704 0.788967 17.9289C0.547495 17.6875 0.426758 17.3948 0.426758 17.0508C0.426758 16.7068 0.547495 16.414 0.788967 16.1726L7.57742 9.38409Z"
          fill="#1D242B"
        />
      </svg>
    </button>
  );
};
export default ReviewNextButton;
