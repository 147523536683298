import Input from "@/components/common/shared/Input/input";
import Button from "@/components/common/shared/Button/button";
import { routes, showVehicleInfo } from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
const ExportVehicleBox = ({ toggleValue, value, handleChangeVehicle, t }) => {
  const { vehicle } = useAppSelector((state) => state.common);
  return (
    <div className={`selectValueBox ${value ? "flex" : "hide"}`}>
      <div className="findByRegistrationBox export">
        <div className="flex justify-between xs:flex-row flex-col">
          <div className="inputBox">
            <Input
              type="text"
              id="vehicle-input"
              disabled
              name="vehicle"
              placeholder={showVehicleInfo(vehicle)}
            />
            <Button
              theme="blue-underlined"
              className="absolute -translate-y-1/2 top-6/12 right-[30px] text-primary-blue-900 max-w-max"
              onClick={() => {
                handleChangeVehicle();
                toggleValue();
              }}
            >
              {t("current_vehicle.change_vehicle")}
            </Button>
          </div>
          <Button type="link" href={routes.parts} theme="green-btn">
            {t("current_vehicle.exploreParts")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ExportVehicleBox;
