"use client";

import Select from "react-select";
import { forwardRef } from "react";
import AsyncSelect from "react-select/async";
import ErrorIcon from "../Input/SvgIcons/errorIcon";

/**
 * @typedef {{ value: string, label: string }} Option
 * @param {{
 *    classes: string,
 *    title: string,
 * 		options: Option[],
 * 		value: Option | null,
 * 		onChange: (Option) => void,
 * 		placeholder: string,
 * 		error: string,
 * 		id: string,
 * 		async: boolean,
 * 		loadOptions: () => Promise<Option[]>
 * }} props
 */
const CustomSelect = (props, ref) => {
  const {
    id,
    title,
    value,
    error,
    async,
    classes,
    onChange,
    isClearable=false,
    placeholder,
    isSearchAllow=false,
    isDisable=false,
    loadOptions,
    options = [],
    labelClasses = "",
  } = props;

  const colourStyles = {
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "rgb(0 47 110)" : null,
        color: "#ffffff",
      };
    },
  };

  return (
    <>
      <div className={`custom-select-box ${isDisable && "opacity-60"} ${classes || ""}`}>
        {!!title && (
          <label
            htmlFor={`react-select-${id}-input`}
            className={`select-label ${labelClasses}`}
          >
            {title}
          </label>
        )}
        {!async && (
          <Select
            ref={ref}
            value={value}
            instanceId={id}
            options={options}
            onChange={onChange}
            isSearchable={isSearchAllow} // Disable search
            styles={colourStyles}
            aria-invalid={!!error}
            isClearable={isClearable}
            isDisabled={isDisable}
            className="select-input"
            placeholder={placeholder}
            classNamePrefix="react-select"
            id={`react-select-${id}-input`}
            {...props}
          />
        )}
        {async && (
          <AsyncSelect
            ref={ref}
            cacheOptions
            value={value}
            defaultOptions
            instanceId={id}
            onChange={onChange}
            isSearchable={isSearchAllow} // Disable search
            styles={colourStyles}
            isDisabled={isDisable}
            aria-invalid={!!error}
            isClearable={isClearable}
            className="select-input"
            placeholder={placeholder}
            loadOptions={loadOptions}
            classNamePrefix="react-select"
            id={`react-select-${id}-input`}
            {...props}
          />
        )}
      </div>
      {error && (
        <div className="error-message">
          <ErrorIcon className="me-[5px] min-w-[13px]" />
          <span>{error}</span>
        </div>
      )}
    </>
  );
};

export default forwardRef(CustomSelect);
